<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="7" xs="3" md="3">
          <h1 class="primary--text">Profile</h1>
        </v-col>

        <v-col cols="6" xs="3" md="3" offset-md="6" align-self="center">
          <v-btn
            small
            rounded
            class="primary float-right"
            to="/overview"
          >
            Back To Overview
          </v-btn>
        </v-col>
      </v-row>
      
      <v-row class="mt-0">
        <v-col cols="12" xs="12" md="12">
          <v-card>
            <v-tabs v-model="tab" show-arrows>
              <v-tab v-for="tab in tabs" :key="tab.icon">
                <v-icon size="20" class="me-3">
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <information/>
              </v-tab-item>
              <v-tab-item>
                <security/>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import Logo from '@/components/common/Logo.vue'
import Information from '@/components/account-settings/Information.vue'
import Security from '@/components/account-settings/Security.vue'
import { mdiKeyOutline, mdiInformationOutline } from '@mdi/js'


export default {
  components: {
    Logo,
    Information,
    Security,
  },
  data: () => {
    const tab = ref('');
    const tabs = [
      { title: 'Information', icon: mdiInformationOutline },
      { title: 'Security', icon: mdiKeyOutline },
    ];
    const attrs = {
      boilerplate: true,
      elevation: 2,
    }

    return {
      tab,
      tabs,
      attrs: attrs,
      isLoading: false,
      icons: {
        mdiKeyOutline,
        mdiInformationOutline,
      },
    }
  },
}
</script>
