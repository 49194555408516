<template>
  <v-card flat class="mt-0">
    <v-form ref="form" @submit.prevent="updatePassword()">
      <div class="px-3">
        <v-card-text class="pt-0">
          <v-row>
            <v-col cols="12" sm="8" md="6"
            >
              <v-row class="px-3 py-3">
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="oldPassword"
                    label="Current Password"
                    :append-icon="isOldPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :type="isOldPasswordVisible ? 'text' : 'password'"
                    :rules="[(v) => !!v || 'Current Password is required']"
                    @click:append="isOldPasswordVisible = !isOldPasswordVisible"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    label="New Password"
                    v-model="newPassword"
                    dense
                    :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    :type="isNewPasswordVisible ? 'text' : 'password'"
                    :rules="[
                      (v) => !!v || 'New Password is required',
                      (v) => (v && v !== oldPassword) || 'New password can not be same current password',
                      password('New Password'),
                    ]"
                    @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="cPassword"
                    :type="isCPasswordVisible ? 'text' : 'password'"
                    :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    label="Confirm New Password"
                    dense
                    :rules="[
                      (v) => !!v || 'Re-enter password is required',
                      match(newPassword),
                    ]"
                    @click:append="isCPasswordVisible = !isCPasswordVisible"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="120"
                src="@/assets/images/3d-characters/pose-m-1.png"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- action buttons -->
        <v-card-actions>
          <v-btn
            small
            text
            type="reset"
            class="error--text rounded-pill px-5 me-3 font-weight-bold"
            @click.prevent="$router.back()"
          > Cancel
          </v-btn>
          <v-btn
            small
            type="submit"
            class="primary rounded-pill px-5" :loading="updateDisabled"
          > Save changes
          </v-btn>
        </v-card-actions>
      </div>
    </v-form>

    <DialogModal
      v-model="alertDialog"
      :title="alertTitle"
      :message="alertMessage"
      confirmButtonText="Ok"
      :cancelButton="false"
      @onConfirmation="alertDialog = false"
    />
  </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import validations from "@/utils/validations";
import {
  REFRESHTOKEN,
  CHANGE_USER_PASSWORD,
  ADD_ALERT,
} from "@/store/_actiontypes";
import DialogModal from "@/components/common/DialogModal";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "@/utils/vee-validate";


export default {
  components: { DialogModal, ValidationObserver, ValidationProvider },
  setup(){
    return {
      ...validations,
      isOldPasswordVisible: false,
      isNewPasswordVisible: false,
      isCPasswordVisible: false,
      oldPassword: "",
      newPassword: "",
      cPassword: "",
      back_to: "",
      updateDisabled: false,
      alertDialog: false,
      alertTitle: "Error",
      alertMessage: "Unknown error occurs",

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  created(){
    this.back_to = this.$router.history._startLocation;
  },
  methods: {
    ...mapActions("account", [
      REFRESHTOKEN,
      CHANGE_USER_PASSWORD,
    ]),
    ...mapActions("alert", [ADD_ALERT]),

    updatePassword(){
      if (!this.$refs.form.validate()) return;
      if (this.newPassword !== this.cPassword) return;

      if (this.$tools.timeToNextToken() < 300){
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then(
          (response) => {
            this.updatePasswordAction();
          },
          (error) => error
        );
      } else {
        this.updatePasswordAction();
      }
    },
    updatePasswordAction(){
      this.updateDisabled = true;
      this.CHANGE_USER_PASSWORD({
        old_password: this.oldPassword,
        new_password: this.newPassword,
      }).then(
        (response) => {
          this.updateDisabled = false;
        },
        (error) => {
          this.updateDisabled = false;
          this.showErrorModal(error);
        }
      );
    },
    showErrorModal(error){
      this.alertTitle = "Error";
      if (error.response && error.response.status){
        this.alertTitle += ": " + error.response.status;
      }
      let errormessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (errormessage){
        this.alertMessage = errormessage;
      } else {
        this.alertMessage = "Unknown Error!";
      }
      this.alertDialog = true;
    },
  },
}
</script>
